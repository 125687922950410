"use strict";
import { useState, useEffect, useCallback, memo, lazy, Suspense } from "react";
import { identify } from "@utils/track";
import { getFavParadas } from "@utils/localstorage";
import type { SimpleParada } from "@utils/localstorage";
import type { TrayectoParada } from "@utils/api-data-types";

const LazyParada = lazy(() => import("./Parada"));

interface ParadaListProps {
  paradas: SimpleParada[];
}

const ParadaList = memo(({ paradas }: ParadaListProps) => (
  <div className="grid grid-cols-1 gap-4 mb-2 link-card-grid md:grid-cols-2">
    {paradas.length > 0 &&
      paradas.map((parada) => (
        <Suspense fallback={<div>Loading...</div>}>
          <LazyParada
            key={parada.id}
            id={parada.id}
            parada={parada as unknown as TrayectoParada}
          />
        </Suspense>
      ))}
  </div>
));

const Favoritos = () => {
  const [favs, setFavs] = useState<SimpleParada[]>([]);

  const loadFavs = useCallback(async () => {
    try {
      const loadedFavs = await getFavParadas();
      const favs = loadedFavs.map((f) => f.id.toString());
      setFavs(loadedFavs);
      identify({
        favs,
      });
    } catch (error) {
      console.error("Error loading favorites:", error);
    }
  }, []);

  useEffect(() => {
    loadFavs();
  }, [loadFavs]);

  if (!favs || !favs.length) {
    return null;
  }

  document.getElementById("favoritos-list-fallback")?.remove();
  document.getElementById("favoritos-text-fallback")?.remove();

  return (
    favs.length > 0 && (
      <Suspense fallback={<div>Loading...</div>}>
        <ParadaList paradas={favs} />
      </Suspense>
    )
  );
};

export default memo(Favoritos);
